/* .sub-pages {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 23px;
  
}
.img-preview {
  width: calc(100% - 32px);
  border-radius: 5px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  height: 105px;
  box-shadow: 1px 1px 15px #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-preview img {
  height: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sub-pages li a {
  color: #414141;
  text-decoration: underline;
}
.sub-pages li a:hover {
  color: #ff3700;
}
.ant-upload-wrapper {
  margin-right: 5px;
}
.ql-snow .ql-editor h1 {
  font-size: 22px;
}
.ql-snow .ql-editor h2 {
  font-size: 18px;
}
.ql-snow .ql-editor h3 {
  font-size: 14px;
}
.ql-snow .ql-editor p {
  font-size: 14px;
}
.ant-form-item-required::after{
  display: none;
}
.ant-form-item-required::before {
  margin: 0 0 0 5px !important;
  order: 2;
}
.ant-form-item-required {
  font-weight: 700;
  font-size: 12px !important;
}
.ant-form-item {
  padding: 0px 6px;
}
.ant-tabs-content-holder {
  padding: 0 10px 40px 10px;
}
.col-pad {
  padding: 0 15px;
}
.demo-logo {
  display: flex;
  align-items: center;
  margin-right: 45px;
}
.ant-menu-submenu {
  margin-left: auto;
}
.demo-logo img {
  filter: brightness(0) invert(1);
  height: 40px;
  width: 89px;
  object-fit: contain;
}
.ant-layout-header {
  padding: 0 15px;
  background: #414141;
}
.ant-tabs .ant-form{
  min-height: 75vh;
}
.main-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  margin: 0 15px 10px 15px;
  padding: 10px 15px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 1px 1px 15px #eeeeee;
}

.main-head h1 {
  margin: 0;
  font-size: 20px;
  color: #000;
  text-align: center;
}
.main-head h3 {
  font-weight: 500;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-dark{
  background: #414141;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-tabs >.ant-tabs-nav {
  padding: 0;
  border-bottom:1px solid #dddddd ;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-overflow {
  display: flex;
  line-height: 45px !important;
}
.ant-tabs.ant-tabs-top {
  background: #ffffff;
  margin: 0 15px 15px 15px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 1px 1px 15px #eeeeee;
}
.ant-card-head-title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.ant-menu-item a {
  color: #ffffff !important;
  font-size: 13px;
}
.ant-layout-header .anticon svg {
  color: #ffffff !important;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-dark.ant-menu-submenu-popup>.ant-menu {
  background: #414141;
}
.ant-card-head {
  background: #fd3703 !important;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-card-bordered {
  border: 1px solid #f0f0f0;
  box-shadow: 1px 1px 20px #f0f0f0;
}
.ant-space-item .ant-form-item {
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.savebtns {
  margin: 10px 15px;
  padding: 10px;
  position: fixed;
  background: #ffffff;
  bottom: 28px;
  left: 0;
  right: 0;
}
.body-pad {
  padding: 60px 0 45px 0;
  min-height: 100vh;
}
.innerbody-pad {
  padding: 60px 0 45px 0;
  min-height: 100vh;
}
.body-pad .ant-col {
  padding: 0;
}
.up-del {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap:6px;
}
.ql-toolbar.ql-snow {
  padding: 3px;
}
.ql-editor {
  padding: 5px 10px;
}
.card-area { 
  margin-bottom: 10px;
  text-align: center;
  border-radius: 6px;  
  overflow: hidden;
}
.card-area a {
  background: #414141;
  box-shadow: 1px 1px 15px #dddddd;
  text-transform: uppercase;
  font-size: 15px;
  padding: 15px;
  letter-spacing: 1px;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  transition: 0.5s;
}
.card-area a:hover {
 background-color: #ff3700;
}
.ant-layout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #ffffff;
  box-shadow: 1px 1px 15px #eeeeee;
  padding: 10px;
}
.ant-layout-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  height: 45px;
}
.ant-tabs-nav-wrap {
  background: #fff;
}

.ant-tabs-tab {
  padding: 10px 20px !important;
  margin: 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  background: #ff3700;
}


.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected, :where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected:hover, :where(.css-dev-only-do-not-override-1ae8k9u).ant-btn-primary {
  background-color: #ff3700;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-tabs .ant-tabs-tab:hover {
  color: #ff3700;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #ff3700;

}
.dashlinks .ant-row .ant-col {
  padding: 0 5px;
}

.dashlinks {
  margin: 0 10px;
}
.del-btn {
  margin-top: 30px;
  text-align: right;
}
.ql-toolbar.ql-snow {
  border-radius: 6px 6px 0 0;
}

.ql-container.ql-snow {
  border-radius: 0 0 6px 6px;
}
.ql-editor {
  min-height: 72px;
}
.ant-message{
  top: 100px !important;
} */

.ant-form-item .ant-form-item-label > label {
	font-size: 12px;
	font-weight: 700;
}
.ant-tabs.ant-tabs-top.tab-none .ant-tabs-nav {display: none;}
 
.ant-tabs.ant-tabs-top.tab-none .ant-tabs-content-holder {
    padding-top: 10px;
}

.sub-pages {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 23px;
}
.img-preview {
  width: calc(100% - 32px);
  border-radius: 5px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.img-preview img {
  height: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sub-pages li a {
  color: #414141;
  text-decoration: underline;
}
.sub-pages li a:hover {
  color: #ff3700;
}
.ant-upload-wrapper {
  margin-right: 5px;
}
.ql-snow .ql-editor h1 {
  font-size: 22px;
}
.ql-snow .ql-editor h2 {
  font-size: 18px;
}
.ql-snow .ql-editor h3 {
  font-size: 14px;
}
.ql-snow .ql-editor p {
  font-size: 14px;
}
.ant-form-item-required::after{
  display: none;
}
.ant-form-item-required::before {
  margin: 0 0 0 5px !important;
  order: 2;
}
.ant-form-item-required {
  font-weight: 700;
  font-size: 12px !important;
}
.ant-form-item {
  padding: 0px 6px;
}
.ant-tabs-content-holder {
  padding: 0 10px 40px 10px;
}
.col-pad {
  padding: 0 15px;
}
.demo-logo {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.ant-menu-submenu {
  margin-left: auto;
}
.demo-logo img {
  filter: brightness(0) invert(1);
  height: 40px;
  width: 89px;
  object-fit: contain;
}
.Linkgenerator {
	border: 1px solid #fff;
	padding: 10px 15px;
	border-radius: 3px;
}

.Linkgenerator:hover{
  background: #fff;
  color: #000 !important;
}

.Linkgenerator svg{
  margin-right: 5px;
  transition: 0.5s;
}
.ant-layout-header .Linkgenerator:hover .anticon svg {
	margin-right: 5px;
	color: #000 !important;
}

.ant-layout-header {
	padding: 0 15px;
	background: #414141;
	z-index: 99 !important;
}
.ant-tabs .ant-form{
  min-height: 75vh;
}
.main-head {
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-start; */
  align-items: center;
  background: #ffffff;
  margin: 0 15px 10px 15px;
  padding: 10px 15px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 1px 1px 15px #eeeeee;
}
.modal-body .ant-form {
	min-height: inherit;
}
.main-head h1 {
  margin: 0;
  font-size: 20px;
  color: #000;
  text-align: center;
}
.main-head h3 {
  font-weight: 500;
}
.sub_tab .ant-tabs-nav .ant-tabs-tab {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-dark{
  background: #414141;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-tabs >.ant-tabs-nav {
  padding: 0;
  border-bottom:1px solid #dddddd ;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-overflow {
  display: flex;
  line-height: 45px !important;
}
.ant-tabs.ant-tabs-top {
	background: #ffffff;
	margin: 0 15px 15px 15px;
	border-radius: 6px;
	overflow: hidden;
	box-shadow: 1px 1px 15px #fff;
	border: 1px solid #eeeeee;
}
.ant-card-head-title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.ant-menu-item a {
  color: #ffffff !important;
  font-size: 13px;
}
.ant-layout-header .anticon svg {
  color: #ffffff !important;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-dark.ant-menu-submenu-popup>.ant-menu {
  background: #414141;
}
.ant-card-head {
  background: #fd3703 !important;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-card-bordered {
  border: 1px solid #f0f0f0;
  box-shadow: 1px 1px 20px #f0f0f0;
}
.ant-space-item .ant-form-item {
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.savebtns {
	margin: 10px 15px 0 15px;
	padding: 10px;
	position: fixed;
	background: #fff;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 5px;
	border: 1px solid #eee;
}
.savebtns1 {
	margin: 25px 33px 22px 93px;
	padding: 10px;
	position: fixed;
	background: #fff;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 5px;
	border: 1px solid #eee;
}

.body-pad {
  padding: 60px 0 45px 0;
  min-height: 100vh;
}
.innerbody-pad {
  padding: 60px 0 45px 0;
  min-height: 100vh;
}
.body-pad .ant-col {
  padding: 0;
}
.up-del {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap:6px;
}
.ql-toolbar.ql-snow {
  padding: 3px;
}
.ql-editor {
  padding: 5px 10px;
}
.card-area { 
  margin-bottom: 10px;
  text-align: center;
  border-radius: 6px;  
  overflow: hidden;
}
.card-area a {
  background: #414141;
  box-shadow: 1px 1px 15px #dddddd;
  text-transform: uppercase;
  font-size: 15px;
  padding: 15px;
  letter-spacing: 1px;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  transition: 0.5s;
}
.card-area a:hover {
 background-color: #ff3700;
}
.ant-layout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #ffffff;
  box-shadow: 1px 1px 15px #eeeeee;
  padding: 10px;
}
.ant-layout-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  height: 45px;
}
.ant-tabs-nav-wrap {
  background: #fff;
}

.ant-tabs-tab {
  padding: 10px 20px !important;
  margin: 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  background: #ff3700;
}


.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected, :where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected:hover, :where(.css-dev-only-do-not-override-1ae8k9u).ant-btn-primary {
  background-color: #ff3700;
}
.ant-btn {
  margin-bottom: 10px;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-btn-default:not(:disabled):not(.ant-btn-disabled){
  color: #ff3700;
  border: 1px solid #ff3700;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #ff3700;
  color: #ffffff;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-tabs .ant-tabs-tab:hover {
  color: #ff3700;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #ff3700;
}
.dashlinks .ant-row .ant-col {
  padding: 0 5px;
}

.dashlinks {
  margin: 0 10px;
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
  font-size: 14px !important;
  padding: 2px 5px;
  border-radius: 6px;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-item-active ,:where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-item-active:hover {
  border-color: #ff3700;
 
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-item-active a ,:where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-item-active a:hover{
  color: #ff3700;
}

.del-btn {
  margin-top: 30px;
  text-align: right;
}
.ql-toolbar.ql-snow {
  border-radius: 6px 6px 0 0;
}

.ql-container.ql-snow {
  border-radius: 0 0 6px 6px;
}
.ql-editor {
  min-height: 72px;
}
.ant-message{
  top: 100px !important;
}

.ant-layout-header ul.ant-menu-overflow.ant-menu {
  background: transparent;
}

.ant-layout-header ul.ant-menu-overflow.ant-menu li {
  line-height: 45px;
  padding-left: 10px;
  padding-right: 10px;
}

.ant-layout-header ul.ant-menu-overflow.ant-menu li.ant-menu-item-selected {
  background: #ff3700;
}
.ant-tabs .ant-tabs-tab:hover {
  color: #ff3700;
}
.ant-menu.ant-menu-sub.ant-menu-vertical {
  background: #414141;
}
.App .ant-btn.ant-btn-primary {
  background: #ff3700;
}

.App .ant-btn.ant-btn-primary:hover {
  background: #ff3700;
}


.ant-form tr:first-child th {
  text-align: left;
  min-width: 235px;
  padding: 10px;
}
.ant-form tr:first-child {
  background: #eee;
}
.ant-form tr td {
  padding: 10px;
}

.note-info {
  padding: 15px;
  background: #e6ffe6;
  margin-bottom: 15px;
}
.note-info h5 {
  margin: 0;
  font-size:14px
}
.note-info ul {
  padding: 0 0 0 15px;
}
.note-info ul li {
  margin-bottom: 5px;
}
.spinner-loading{
     display: flex;
     justify-content: center;
     align-items: center;
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     z-Index: 9999;
     background-color: rgba(0, 0, 0, 0.3);
}
.spin-icon{
  font-size: 24px;
  color: #ff3700;
}

.spin-icon-pdf{
  font-size: 24px;
  color: #ff3700;
  margin-left: 10px;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-carousel .slick-dots li.slick-active button {
  background: #ff3700;
  opacity: 1;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #ff3700;
  border: 0;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s;
}

.ant-carousel .slick-dots li button {
  width: 5px;
  height: 5px;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #ff3700;
}


.slick-arrow {
  content: '>';
  background: var(--orange);
}
.slick-arrow {
}
.slick-arrow {
  background: #ff3700;
}
.ant-carousel .slick-prev, .ant-carousel .slick-next {
  background: #fff !important;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 25px;
  width: 21px;
}
.slick-slide img {
  width: 100%;
  height: 103px;
  border-radius: 8px;
  object-fit: contain;
}
.ant-carousel .slick-prev:before, .ant-carousel .slick-next:before {
  color: #000;
  font-size: 8px;
}
.slick-slide {
  border: 1px solid #ccc;
  border-radius: 5px;
}


.banner-cl {
  padding-left: 25px;
  position: relative;
  padding-right: 20px;
}
.ant-carousel {
  width: 80%;
}
.banner-cl .ant-upload-wrapper {
  position: absolute;
  right: 0;
  top: 0;
}


.slick-dots.slick-dots-bottom {
}
.banner-cl .slick-dots {
  bottom: -17px;
}
.banner-cl .slick-dots li {
  width: 10px;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-carousel .slick-dots li {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
}
.ant-table-cell {
  min-width: 25%;
}


.car-cont{
  position: relative;
}
.car-cont .ant-upload-wrapper {
	position: relative;
}

.portsec input {
  padding: 5px 10px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  outline:none !important
}

.portsec button {
  padding: 8px 10px;
  border-radius: 6px;
  border: none;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  background: #ff3700;
  outline:none !important
}
.tab-none .ant-tabs-nav {display: none;}
 
.ant-tabs.ant-tabs-top.tab-none .ant-tabs-content-holder {
    padding-top: 10px;
}
.ant-form-item .ant-form-item-label {
  display: block;
  width: 100%;
  text-align: left;
}
th.ant-table-cell {
  font-size: 13px;
  padding: 5px  6px!important;
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.8s ease-in-out;
}

.modal-overlay.open {
  opacity: 1;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 550px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  height: 615px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  flex-grow: 1;
  margin-bottom: 20px;
  overflow-y: auto;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-button:hover {
  background-color: #0056b3;
}
